import { getEstimates } from '../../../HttpRequests/estimates'
import { loadEstimates } from '../State/project'
import { setFetching } from '../State/fetching'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = getProps().match.params

  const params = {
    'filter[project_id]': id,
    include: 'project',
  }

  dispatch(setFetching(true))

  try {

    const response = await getEstimates({ id, params })

    dispatch(loadEstimates(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
