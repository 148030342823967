exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1KIhj8cSgtNRsBOs1_osgo {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n._3746a7LIcM9QnowV3xDipz {\n  display: flex;\n}\n\n._1vHc7lNZW6OwecEa0dvIDj {\n  color: #999;\n}\n\n._1oY7n91vAcjPR6mvfp_cD_ {\n  width: 120px;\n  text-align: right;\n}\n\n._2_2XVbQm9i_L55w1mPddB_ {\n  width: 50px;\n  text-align: right;\n  color: #999;\n}\n\n._2eaWlvKe7zapZBzE9tcYjP {\n  color: rgb(0, 168, 0);\n}\n\n._2g8W4PZewyc6gVaITm6DZc {\n  color: red;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/TargetCosts/Components/Actual/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,SAAS;CACV;;AAED;EACE,cAAc;CACf;;AAED;EACE,YAAY;CACb;;AAED;EACE,aAAa;EACb,kBAAkB;CACnB;;AAED;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;CACb;;AAED;EACE,sBAAsB;CACvB;;AAED;EACE,WAAW;CACZ","file":"styles.css","sourcesContent":[".outlets {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.outlet {\n  display: flex;\n}\n\n.label {\n  color: #999;\n}\n\n.value {\n  width: 120px;\n  text-align: right;\n}\n\n.suffix {\n  width: 50px;\n  text-align: right;\n  color: #999;\n}\n\n.green {\n  color: rgb(0, 168, 0);\n}\n\n.red {\n  color: red;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"outlets": "_1KIhj8cSgtNRsBOs1_osgo",
	"outlet": "_3746a7LIcM9QnowV3xDipz",
	"label": "_1vHc7lNZW6OwecEa0dvIDj",
	"value": "_1oY7n91vAcjPR6mvfp_cD_",
	"suffix": "_2_2XVbQm9i_L55w1mPddB_",
	"green": "_2eaWlvKe7zapZBzE9tcYjP",
	"red": "_2g8W4PZewyc6gVaITm6DZc"
};