import { connect } from 'react-redux'
import Valuations from './Valuations'

import componentWillMount from './Handlers/componentWillMount'
import onChangeTab from './Handlers/onChangeTab'
import onClickNewValuationDate from './Handlers/onClickNewValuationDate'
import onClickValuationDate from './Handlers/onClickValuationDate'

const mapStateToProps = (state) => ({
  activeTab: state.screens.ProjectView.Valuations.activeTab,
})

const actionCreators = {
  componentWillMount,
  onChangeTab,
  onClickNewValuationDate,
  onClickValuationDate,
}

export default connect(mapStateToProps, actionCreators)(Valuations)
