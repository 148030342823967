import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from 'antd'
import numeral from 'numeral'
import styles from './styles.css'

const Spacer = () => <div style={{ flex: 1 }} />

const Targets = ({ project, projectTargetAmounts, fetchingProjectTargetAmounts, onEditField }) => {

  const onBlurNumber = (field, value) => {
    let $value = Number(value)
    if (Number.isNaN($value)) return
    $value = parseFloat($value).toFixed(2)
    if ($value === project[field]) return
    onEditField(field, $value)
  }

  return (
    <Card title='Targets' loading={fetchingProjectTargetAmounts}>

      <div className={styles.outlets}>

        <div className={styles.outlet}>
          <div className={styles.label}>Project Value</div>
          <Spacer />
          <div className={styles.value}>
            <b>{`£${numeral(project.value_total).format('0,0.00')}`}</b>
          </div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Hourly Rate</div>
          <Spacer />
          <Typography.Text
            editable={{
              maxLength: 5,
              onChange: value => onBlurNumber('hourly_rate', value)
            }}
          >
            {project.hourly_rate}
          </Typography.Text>
          <div style={{ width: 32 }} />
        </div>

        <br />

        <div className={styles.outlet}>
          <div className={styles.label}>Target Cost</div>
          <Spacer />
          <div className={styles.value}>
            <b>{`£${numeral(projectTargetAmounts.target_cost_total_amount).format('0,0.00')}`}</b>
          </div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_cost_total_percentage).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Target Labour</div>
          <Spacer />
          <div className={styles.value}>{`£${numeral(projectTargetAmounts.target_labour_total_amount).format('0,0.00')}`}</div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_labour_total_percentage).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Target Other</div>
          <Spacer />
          <div className={styles.value}>{`£${numeral(projectTargetAmounts.target_other_total_amount).format('0,0.00')}`}</div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_other_total_percentage).format('0')}%`}</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Target Profit</div>
          <Spacer />
          <div className={styles.value}>
            <b>{`£${numeral(projectTargetAmounts.target_profit_total_amount).format('0,0.00')}`}</b>
          </div>
          <div className={styles.suffix}>{`${numeral(projectTargetAmounts.target_profit_total_percentage).format('0')}%`}</div>
        </div>

        <br />

        <div className={styles.outlet}>
          <div className={styles.label}>Target Man Days</div>
          <Spacer />
          <div className={styles.value}>
            <b>{numeral(projectTargetAmounts.man_days_total_amount).format('0,0.00')}</b>
          </div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

      </div>

    </Card>
  )

}

Targets.propTypes = {
  project: PropTypes.object.isRequired,
  projectTargetAmounts: PropTypes.object.isRequired,
  fetchingProjectTargetAmounts: PropTypes.bool.isRequired,
  onEditField: PropTypes.func.isRequired,
}

export default Targets
