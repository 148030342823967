import { getProps } from '../../../props'
import { getProjectTargetAmounts } from '../../../../../HttpRequests/projects'
import { setProjectTargetAmounts } from '../State/projectTargetAmounts'
import { setFetchingProjectTargetAmounts } from '../State/fetchingProjectTargetAmounts'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = getProps().match.params

  dispatch(setFetchingProjectTargetAmounts(true))

  try {

    const response = await getProjectTargetAmounts({ id })

    dispatch(setProjectTargetAmounts(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetchingProjectTargetAmounts(false))

  }

}
