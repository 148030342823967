import { setProjectTargetCosts } from '../../../State/projectTargetCosts'
import updateProjectTargetCost from '../../../Services/updateProjectTargetCost'

export default (id, field, value) => (dispatch, getState) => {
  
  const { projectTargetCosts } = getState().screens.ProjectView.TargetCosts

  const newProjectTargetCosts = []

  projectTargetCosts.forEach(projectTargetCost => {

    if (projectTargetCost.id !== id) {
      newProjectTargetCosts.push(projectTargetCost)
      return
    }

    const newProjectTargetCost = {
      ...projectTargetCost,
      [field]: value,
    }

    const { labour_percentage: labourPercentage, other_percentage: otherPercentage } = newProjectTargetCost

    const costPercentage = Number(labourPercentage) + Number(otherPercentage)

    if (costPercentage > 100) {
      newProjectTargetCosts.push(projectTargetCost)
      return
    }

    newProjectTargetCost.cost_percentage = parseFloat(costPercentage).toFixed(2)
    newProjectTargetCost.profit_percentage = parseFloat(100 - costPercentage).toFixed(2)
    
    newProjectTargetCosts.push(newProjectTargetCost)

    dispatch(updateProjectTargetCost(id, field, value))

  })

  dispatch(setProjectTargetCosts(newProjectTargetCosts))

}
