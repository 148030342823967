import { connect } from 'react-redux'
import ServiceTypes from './ServiceTypes'

import onEditField from './Handlers/onEditField'

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes,
  project: state.screens.ProjectView.project,
  projectTargetCosts: state.screens.ProjectView.TargetCosts.projectTargetCosts,
  fetchingProjectTargetCosts: state.screens.ProjectView.TargetCosts.fetchingProjectTargetCosts,
})

const actionCreators = {
  onEditField,
}

export default connect(mapStateToProps, actionCreators)(ServiceTypes)
