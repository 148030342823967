import { connect } from 'react-redux'
import Expected from './Expected'

const mapStateToProps = (state) => ({
  project: state.screens.ProjectView.project,
  projectTargetAmounts: state.screens.ProjectView.TargetCosts.projectTargetAmounts,
  fetchingProjectTargetAmounts: state.screens.ProjectView.TargetCosts.fetchingProjectTargetAmounts,
})

const actionCreators = {
  //
}

export default connect(mapStateToProps, actionCreators)(Expected)
