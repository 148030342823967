import { getProps } from '../../../props'
import { getProjectTargetCosts } from '../../../../../HttpRequests/projectTargetCosts'
import { setProjectTargetCosts } from '../State/projectTargetCosts'
import { setFetchingProjectTargetCosts } from '../State/fetchingProjectTargetCosts'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = getProps().match.params

  const params = {
    'filter[project_id]': id,
  }

  dispatch(setFetchingProjectTargetCosts(true))

  try {

    const response = await getProjectTargetCosts({ params })

    dispatch(setProjectTargetCosts(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetchingProjectTargetCosts(false))

  }

}
