import { createAction, handleActions } from 'redux-actions'

const setProjectTargetCosts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/SET_PROJECT_TARGET_COSTS')
const resetProjectTargetCosts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/RESET_PROJECT_TARGET_COSTS')

const defaultState = []

const reducer = handleActions(
  {
    [setProjectTargetCosts]: (_, { payload }) => payload,
    [resetProjectTargetCosts]: () => defaultState,
  },
  defaultState,
)

export { setProjectTargetCosts, resetProjectTargetCosts, reducer }
