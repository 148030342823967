// @flow

import * as React from 'react';

import Toolbar from './Components/Toolbar';
import Table from './Components/Table';

type Props = {
  project: {},
  fetching: Boolean,
  estimateStatuses: [],
  estimateTenderStatuses: [],
  estimateLatestActions: [],
  users: [],
  searchTerm: string,

  componentWillMount: Function,
  onChangeEstimateField: Function,
  onClickNewEstimate: Function,
  onChangeSearch: Function,
  onChangeSelectedRows: Function,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount()

  render () {
    
    const {
      project,
      fetching,
      searchTerm,
      estimateStatuses,
      estimateTenderStatuses,
      estimateLatestActions,
      users,
      onChangeEstimateField,
      onClickNewEstimate,
      onChangeSearch,
      onChangeSelectedRows,
    } = this.props;

    return (
      <React.Fragment>

        <Toolbar
          searchTerm={searchTerm}
          onClickNewEstimate={onClickNewEstimate}
          onChangeSearch={onChangeSearch}
        />

        <Table
          project={project}
          fetching={fetching}
          estimateStatuses={estimateStatuses}
          estimateTenderStatuses={estimateTenderStatuses}
          estimateLatestActions={estimateLatestActions}
          users={users}
          onChangeEstimateField={onChangeEstimateField}
          onChangeSelectedRows={onChangeSelectedRows}
        />

      </React.Fragment>
    )
  }
}