import { createAction, handleActions } from 'redux-actions';

const loadProject = createAction("SCREENS/PROJECT_VIEW/LOAD_PROJECT");
const updateStimateState = createAction("SCREENS/PROJECT_VIEW/UPDATE_ESTIMATE_PROJECT");
const updateProjectTotalCost = createAction("SCREENS/PROJECT_VIEW/UPDATE_PROJECT_TOTAL_COST");
const loadEstimates = createAction("SCREENS/PROJECT_VIEW/LOAD_ESTIMATES");
const loadOrders = createAction("SCREENS/PROJECT_VIEW/LOAD_ORDERS");
const loadValuationDates = createAction("SCREENS/PROJECT_VIEW/LOAD_VALUATION_DATES");
const loadValuations = createAction("SCREENS/PROJECT_VIEW/LOAD_VALUATIONS");
const loadInvoices = createAction("SCREENS/PROJECT_VIEW/LOAD_INVOICES");
const loadRetentionPayments = createAction("SCREENS/PROJECT_VIEW/LOAD_RETENTION_PAYMENTS");
const loadRamsRecords = createAction("SCREENS/PROJECT_VIEW/LOAD_RAMS_RECORDS");
const clearProject = createAction("SCREENS/PROJECT_VIEW/CLEAR_PROJECT");

const defaultState = null

const reducer = handleActions(
  {
    [loadProject]: (state, action) => action.payload,
    [updateStimateState]: (state, action) => Object.assign({}, state, {
      estimates: action.payload
    }),
    [updateProjectTotalCost]: (state, action) => Object.assign({}, state, {
      total_cost: action.payload
    }),
    [loadEstimates]: (state, { payload }) => ({
      ...state,
      estimates: payload,
    }),
    [loadOrders]: (state, { payload }) => ({
      ...state,
      orders: payload,
    }),
    [loadValuationDates]: (state, { payload }) => ({
      ...state,
      valuation_dates: payload,
    }),
    [loadValuations]: (state, { payload }) => ({
      ...state,
      valuations: payload,
    }),
    [loadInvoices]: (state, { payload }) => ({
      ...state,
      invoices: payload,
    }),
    [loadRetentionPayments]: (state, { payload }) => ({
      ...state,
      retention_payments: payload,
    }),
    [loadRamsRecords]: (state, { payload }) => ({
      ...state,
      rams_records: payload,
    }),
    [clearProject]: () => null
  },
  defaultState
);

export {
  loadProject,
  updateStimateState,
  updateProjectTotalCost,
  loadEstimates,
  loadOrders,
  loadValuationDates,
  loadValuations,
  loadInvoices,
  loadRetentionPayments,
  loadRamsRecords,
  clearProject,
  reducer,
};