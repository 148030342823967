/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import classnames from 'classnames'
import formatNumber from '../../Utils/formatNumber'
import styles from './styles.css'

const Spacer = () => <div style={{ flex: 1 }} />

const Actual = ({ project, projectTargetAmounts, fetchingProjectTargetAmounts }) => {

  const valueTotal = Number(project.value_total)
  const valueCompleted = Number(project.value_completed)
  const valueRemaining = Number(project.value_remaining)

  const salesTotal = Number(project.sales_total)
  const salesLabour = Number(project.sales_labour)
  const salesMaterials = Number(project.sales_materials)
  const salesEquipment = Number(project.sales_equipment)
  const salesOther = salesMaterials + salesEquipment
  const profit = valueCompleted - salesTotal

  const salesTotalPercentage = valueCompleted ? (salesTotal / valueCompleted) * 100 : (valueTotal ? (salesTotal / valueTotal) * 100 : 0)
  const salesLabourPercentage = valueCompleted ? (salesLabour / valueCompleted) * 100 : (valueTotal ? (salesLabour / valueTotal) * 100 : 0)
  const salesOtherPercentage = valueCompleted ? (salesOther / valueCompleted) * 100 : (valueTotal ? (salesOther / valueTotal) * 100 : 0)
  const profitPercentage = valueCompleted ? (profit / valueCompleted) * 100 : (valueTotal ? (profit / valueTotal) * 100 : 0)

  const salesTotalTargetPercentage = projectTargetAmounts.target_cost_total_amount ? (salesTotal / projectTargetAmounts.target_cost_total_amount) * 100 : 0
  const salesLabourTargetPercentage = projectTargetAmounts.target_labour_total_amount ? (salesLabour / projectTargetAmounts.target_labour_total_amount) * 100 : 0
  const salesOtherTargetPercentage = projectTargetAmounts.target_other_total_amount ? (salesOther / projectTargetAmounts.target_other_total_amount) * 100 : 0

  return (
    <Card title='Actual' loading={fetchingProjectTargetAmounts}>

      <div className={styles.outlets}>

        <div className={styles.outlet}>
          <div className={styles.label}>Project Completion</div>
          <Spacer />
          <div className={styles.value}>
            <b>{formatNumber(valueCompleted, '0,0.00', '£')}</b>
          </div>
          <div className={styles.suffix}>{`${formatNumber((valueCompleted / valueTotal) * 100, '0')}%`}</div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Project Remaining</div>
          <Spacer />
          <div className={styles.value}>
            {formatNumber(valueRemaining, '0,0.00', '£')}
          </div>
          <div className={styles.suffix}>{`${formatNumber((valueRemaining / valueTotal) * 100, '0')}%`}</div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

        <br />

        <div className={styles.outlet}>
          <div className={styles.label}>Actual Cost</div>
          <Spacer />
          <div className={styles.value}>
            <b>{formatNumber(salesTotal, '0,0.00', '£')}</b>
          </div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: !valueCompleted || salesTotalPercentage < projectTargetAmounts.target_cost_completed_percentage,
              [styles.red]: valueCompleted && salesTotalPercentage > projectTargetAmounts.target_cost_completed_percentage,
            })}
          >
            {`${formatNumber(salesTotalPercentage, '0')}%`}
          </div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: salesTotalTargetPercentage < 100,
              [styles.red]: salesTotalTargetPercentage > 100,
            })}
          >
            {`${formatNumber(salesTotalTargetPercentage, '0')}%`}
          </div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Actual Labour</div>
          <Spacer />
          <div className={styles.value}>{formatNumber(salesLabour, '0,0.00', '£')}</div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: !valueCompleted || salesLabourPercentage < projectTargetAmounts.target_labour_completed_percentage,
              [styles.red]: valueCompleted && salesLabourPercentage > projectTargetAmounts.target_labour_completed_percentage,
            })}
          >
            {`${formatNumber(salesLabourPercentage, '0')}%`}
          </div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: salesLabourTargetPercentage < 100,
              [styles.red]: salesLabourTargetPercentage > 100,
            })}
          >
            {`${formatNumber(salesLabourTargetPercentage, '0')}%`}
          </div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Actual Other</div>
          <Spacer />
          <div className={styles.value}>{formatNumber(salesOther, '0,0.00', '£')}</div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: !valueCompleted || salesOtherPercentage < projectTargetAmounts.target_other_completed_percentage,
              [styles.red]: valueCompleted && salesOtherPercentage > projectTargetAmounts.target_other_completed_percentage,
            })}
          >
            {`${formatNumber(salesOtherPercentage, '0')}%`}
          </div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: salesOtherTargetPercentage < 100,
              [styles.red]: salesOtherTargetPercentage > 100,
            })}
          >
            {`${formatNumber(salesOtherTargetPercentage, '0')}%`}
          </div>
        </div>

        <div className={styles.outlet}>
          <div className={styles.label}>Actual Profit</div>
          <Spacer />
          <div className={styles.value}>
            <b>{formatNumber(profit, '0,0.00', '£')}</b>
          </div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: !valueCompleted || profitPercentage > projectTargetAmounts.target_profit_completed_percentage,
              [styles.red]: valueCompleted && profitPercentage < projectTargetAmounts.target_profit_completed_percentage,
            })}
          >
            {`${formatNumber(profitPercentage, '0')}%`}
          </div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

        <br />

        <div className={styles.outlet}>
          <div className={styles.label}>Actual Man Days</div>
          <Spacer />
          <div className={styles.value}>
            <b>{formatNumber(projectTargetAmounts.man_days_actual_amount, '0,0.00', '£')}</b>
          </div>
          <div
            className={classnames(styles.suffix, {
              [styles.green]: !valueCompleted || projectTargetAmounts.man_days_actual_percentage < projectTargetAmounts.man_days_expected_percentage,
              [styles.red]: valueCompleted && projectTargetAmounts.man_days_actual_percentage > projectTargetAmounts.man_days_expected_percentage,
            })}
          >
            {`${formatNumber(projectTargetAmounts.man_days_actual_percentage, '0')}%`}
          </div>
          <div className={styles.suffix}>&nbsp;</div>
        </div>

      </div>

    </Card>
  )

}

Actual.propTypes = {
  project: PropTypes.object.isRequired,
  projectTargetAmounts: PropTypes.object.isRequired,
  fetchingProjectTargetAmounts: PropTypes.bool.isRequired,
}

export default Actual
