// @flow

import * as React from 'react';
import { Spin } from 'antd'
import styles from './styles.css';
import List from './Components/List';

type Props = {
  project: {},
  componentWillMount: Function,
  onClickViewEmail: Function,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount()

  render(){

    const {
      project,
      onClickViewEmail
    } = this.props;

    if (!project.audit_trail) return (
      <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin />
      </div>
    )
    
    return(
      <div className={styles.wrapper}>

        <div className={styles.body}>
          
          <List project={project} onClickViewEmail={onClickViewEmail} />

        </div>

      </div>
    )
  }
}