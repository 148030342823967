import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

import ServiceTypes from './Components/ServiceTypes'
import Targets from './Components/Targets'
import Expected from './Components/Expected'
import Actual from './Components/Actual'

const Spacer = () => <div style={{ height: 16 }} />

const TargetCosts = ({ componentDidMount, componentWillUnmount }) => {

  useEffect(() => {
    componentDidMount()
    return componentWillUnmount
  }, [])
  
  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={24}>
          <ServiceTypes />
        </Col>
      </Row>
      <Spacer />
      <Row gutter={16}>
        <Col span={8}>
          <Targets />
        </Col>
        <Col span={8}>
          <Expected />
        </Col>
        <Col span={8}>
          <Actual />
        </Col>
      </Row>
    </React.Fragment>
  )

}

TargetCosts.propTypes = {
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
}

export default TargetCosts
