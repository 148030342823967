import numeral from 'numeral'

const formatNumber = (value, format = '0.00', prefix = null) => {
  if (value < 0) {
    return `-${prefix || ''}${numeral(Math.abs(value)).format(format)}`
  }
  
  return `${prefix || ''}${numeral(value).format(format)}`
}

export default formatNumber
