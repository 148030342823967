import { combineReducers } from 'redux'

import { reducer as projectTargetCosts } from './State/projectTargetCosts'
import { reducer as projectTargetAmounts } from './State/projectTargetAmounts'
import { reducer as fetchingProjectTargetCosts } from './State/fetchingProjectTargetCosts'
import { reducer as fetchingProjectTargetAmounts } from './State/fetchingProjectTargetAmounts'

export default combineReducers({
  projectTargetCosts,
  projectTargetAmounts,
  fetchingProjectTargetCosts,
  fetchingProjectTargetAmounts,
})
