import moment from 'moment'

export default controls => {

  const params = {
    period_index: controls.periodIndex,
    day_of_week: controls.dayOfWeek,
    y_axis: controls.yAxis,
    im_hide_empty: controls.hideEmpty ? 1 : undefined,
  }

  if (controls.yAxis === 'projects') {
    params.search = controls.projectSearch || undefined
    params.db_contracts_manager = controls.contractsManagerId.join() || undefined
    params.db_status_key = controls.statusKey.join() || undefined
    params.db_phase_key = controls.phaseKey.join() || undefined
    if (controls.startDateBetween.length === 2) {
      params.db_start_date_between = controls.startDateBetween.map(date => moment(date).format('YYYY-MM-DD')).join()
    }
    params.db_omitted = controls.omitted ? 1 : 0 // false is a valid value
    params.db_file_finishing_schedule = controls.noFinishingSchedule ? 0 : undefined
    params.db_file_programme = controls.noProgramme ? 0 : undefined
    params.db_induction_required = controls.inductionRequired ? 1 : undefined
    params.db_weekly_returns = controls.weeklyReturns ? 1 : undefined
    params.db_terms_and_conditions = controls.termsAndConditions ? 1 : undefined
    params.db_own_rams = controls.ownRams ? 1 : undefined
    params.db_no_valuation_this_month = controls.noValuationThisMonth ? 1 : undefined
    params.im_missing_rams = controls.missingRams ? 1 : undefined
    params.im_expiring_rams = controls.expiringRams ? 1 : undefined
    params.im_expired_rams = controls.expiredRams ? 1 : undefined
    params.summary = controls.summary || undefined
    params.sort_by = controls.projectSortBy || undefined
  }

  if (controls.yAxis === 'subcontractors') {
    params.search = controls.subcontractorSearch || undefined
    params.db_card_type = controls.cardType.join() || undefined
  }

  if ((controls.yAxis === 'projects' && !controls.summary) || controls.yAxis === 'subcontractors') {
    params.im_unconfirmed_or_required = controls.unconfirmedOrRequired ? 1 : undefined
    params.im_unattended = controls.unattended ? 1 : undefined
    params.im_unavailable_or_absent = controls.unavailableOrAbsent ? 1 : undefined
    params.im_duplicated = controls.duplicated ? 1 : undefined
    params.im_unexpected = controls.unexpected ? 1 : undefined
    params.im_attended = controls.attended ? 1 : undefined
    params.im_alerts = controls.alerts ? 1 : undefined
    params.im_switched = controls.switched ? 1 : undefined
    params.im_price_work = controls.priceWork ? 1 : undefined
  }

  return params

}
