import { connect } from 'react-redux'
import TargetCosts from './TargetCosts'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'

const mapStateToProps = (state) => ({
  //
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(TargetCosts)
