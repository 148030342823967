import React from 'react';
import { Field } from 'redux-form';
import { InputField, InputNumberField, SelectField, CheckboxField, DatePickerField } from 'FormFields';

const labelColSpan = 8;
const wrapperColSpan = 14;

type Props = {
  submitting: boolean,
  preferredSupplier: boolean,
  clientRiskLevels: []
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      submitting,
      preferredSupplier,
      clientRiskLevels
    } = this.props;

    const clientRiskLevelsOptions = clientRiskLevels.map(item => ({ value: item.key, label: item.title }));

    return (

      <div style={{ display: 'flex' }}>

        <div style={{ flex: 1 }}>

          <Field
            required
            component={InputField}
            name="name"
            label="Name"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="alias_name"
            label="Aliases"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />
          
          <Field
            component={InputField}
            name="telephone"
            label="Telephone"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="email"
            label="Email"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="website"
            label="Website"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="company_number"
            label="Company No"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={CheckboxField}
            name="preferred_supplier"
            label=" "
            text="Preferred Supplier"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          {preferredSupplier && (
            <Field
              component={InputNumberField}
              name="preferred_supplier_perc"
              label="Pref. Supplier %"
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
            />
          )}

          <br />
          <br />

          <Field
            component={InputField}
            name="billing_address_line_1"
            placeholder="Address"
            label="Billing Address"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputField}
            name="billing_address_line_2"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />


          <Field
            component={InputField}
            name="billing_address_line_3"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />


          <Field
            component={InputField}
            name="billing_town_or_city"
            placeholder="Town / City"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={InputField}
            name="billing_county"
            placeholder="County"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            component={InputField}
            name="billing_postcode"
            placeholder="Postcode"
            label=" "
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />
        
        </div>

        <div style={{ width: 400 }}>

          <Field
            required
            component={SelectField}
            name="billing_type"
            label="Billing Type"
            disabled={submitting}
            options={[
              { value: "Application", label: "Application" },
              { value: "Client Valuation", label: "Client Valuation" },
              { value: "Invoice", label: "Invoice" },
              { value: "Unknown", label: "Unknown" },
            ]}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputNumberField}
            name="payment_terms_days"
            label="Payment Terms"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: 8 }}
          />

          <Field
            component={CheckboxField}
            name="payment_terms_end_of_month"
            label=" "
            text="End of Month"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />

          <Field
            required
            component={SelectField}
            name="client_risk_level_key"
            label="Risk Level"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            options={clientRiskLevelsOptions}
            allowClear={false}
            showSearch={false}
          />

          <Field
            component={InputField}
            name="client_risk_level_notes"
            label="Risk Level Notes"
            disabled={submitting}
            autoComplete="off"
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputNumberField}
            name="credit_limit"
            label="Credit Limit (£)"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputNumberField}
            name="aldermore_limit"
            label="Experian Limit (£)"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={InputNumberField}
            name="value_outstanding"
            label="Outstanding (£)"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={DatePickerField}
            name="value_outstanding_review_date"
            label="Review Date"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
          />

          <Field
            component={CheckboxField}
            name="own_rams"
            label=" "
            text="Own RAMS"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            colon={false}
          />
        
        </div>

      </div>

    )
  }
}