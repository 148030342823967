import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from 'antd'
import { keyBy } from 'lodash'
import styles from './styles.css'

const ServiceTypes = ({ serviceTypes, project, projectTargetCosts, fetchingProjectTargetCosts, onEditField }) => {

  const serviceTypesKeyed = keyBy(serviceTypes, 'key')

  const serviceTypesUsed = Object.keys(project.item_type_summary)

  const onBlurNumber = (projectTargetCost, field, value) => {
    let $value = Number(value)
    if (Number.isNaN($value)) return
    if ($value > 100) return
    $value = parseFloat($value).toFixed(2)
    if ($value === projectTargetCost[field]) return
    onEditField(projectTargetCost.id, field, $value)
  }
  
  return (
    <Card title='Services' loading={fetchingProjectTargetCosts}>
      
      <div className={styles.serviceTypesWrapper}>
      
        {projectTargetCosts.filter((projectTargetCost) => serviceTypesUsed.includes(projectTargetCost.service_type)).map(projectTargetCost => {

          const serviceType = serviceTypesKeyed[projectTargetCost.service_type]

          return (

            <div key={projectTargetCost.id} className={styles.serviceTypeWrapper}>

              <div
                className={styles.serviceTypeIcon}
                style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
              >
                {serviceType.key}
              </div>

              <div className={styles.serviceTypeContent}>

                <div className={styles.serviceTypeHeader}>{serviceType.title}</div>

                <div className={styles.serviceTypeOutlets}>

                  <div className={styles.serviceTypeOutlet}>
                    <div className={styles.serviceTypeLabel}>Cost</div>
                    <div className={styles.serviceTypeValue}>{projectTargetCost.cost_percentage}</div>
                  </div>

                  <div className={styles.serviceTypeOutlet}>
                    <div className={styles.serviceTypeLabel}>Labour</div>
                    <Typography.Text
                      editable={{
                        maxLength: 5,
                        onChange: value => onBlurNumber(projectTargetCost, 'labour_percentage', value)
                      }}
                    >
                      {projectTargetCost.labour_percentage}
                    </Typography.Text>
                  </div>

                  <div className={styles.serviceTypeOutlet}>
                    <div className={styles.serviceTypeLabel}>Other</div>
                    <Typography.Text
                      editable={{
                        maxLength: 5,
                        onChange: value => onBlurNumber(projectTargetCost, 'other_percentage', value)
                      }}
                    >
                      {projectTargetCost.other_percentage}
                    </Typography.Text>
                  </div>

                  <div className={styles.serviceTypeOutlet}>
                    <div className={styles.serviceTypeLabel}>Profit</div>
                    <div className={styles.serviceTypeValue}>{projectTargetCost.profit_percentage}</div>
                  </div>

                </div>

              </div>

            </div>
  
          )

        })}

      </div>

    </Card>
  )

}

ServiceTypes.propTypes = {
  serviceTypes: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  projectTargetCosts: PropTypes.array.isRequired,
  fetchingProjectTargetCosts: PropTypes.bool.isRequired,
  onEditField: PropTypes.func.isRequired,
}

export default ServiceTypes
