// @flow

import { getProject } from '../../../HttpRequests/projects';
import { loadProject } from '../State/project';
import { getProps } from '../props';
import handleException from '../../../Core/Helpers/handleException';

export default (auditTrail = false) => async (dispatch: Function) => {
  
  try {
    
    const { id } = getProps().match.params;

    const params = {};
    
    params.include = [
      'client_site',
      'client_site.client.client_contacts',
      'client_contact',
      'contracts_manager',
      'other_contracts_manager',
      'supervisor',
    ].join();
    
    const append = [
      'status',
      'phase',
      'action_statuses',
      'updatable_fields'
    ]

    if (auditTrail) {
      append.push('audit_trail')
    }
    
    params.append = append.join();

    const response = await getProject({ id, params });

    const project = response.data.data;

    dispatch(loadProject(project));

  } catch (error) {

    handleException(error);

  }
}