import { connect } from 'react-redux'
import Component from './Component'

import componentWillMount from './Handlers/componentWillMount'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentWillMount,
}

export default connect(mapStateToProps, actionCreators)(Component)
