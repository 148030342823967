import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import { sortBy, reverse } from 'lodash'
import { NavLink } from 'react-router-dom'
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Table, Popover, Input, Tag } from 'antd';
import { getTeamUrl } from 'Common';

export default class _Invoices_ extends React.PureComponent {

  static propTypes = {
    project: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string.isRequired,
    componentWillMount: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
  }

  componentWillMount = () => this.props.componentWillMount()

  render () {

    const {
      project,
      fetching,
      searchTerm,
      onChangeSearch,
    } = this.props

    const columns = [

      { 
        title: 'Invoice', 
        key: 'id',
        sorter: false,
        width: 155,
        render: (text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`invoices/${record.id}/view`)}>
            {record.title}
          </NavLink>
        ),
      },

      { 
        title: ' ', 
        key: 'notes',
        sorter: false,
        width: 35,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },
      
      {
        title: 'Total',
        key: 'total',
        width: 150,
        sorter: false,
        render: (text, record) => (
          <div>
            <span style={{ marginRight: 10, color: record.subtotal < 0 ? 'orange' : 'inherit' }}>
              {`£${numeral(record.subtotal).format('0,0.00')}`}
            </span>
            {record.subtotal < 0 && (
              <Tag color="orange">Credit</Tag>
            )}
          </div>
        ),
      },
      
      {
        title: 'Date',
        key: 'date',
        width: 150,
        sorter: false,
        render: (text, record) => record.date ? moment(record.date).format('DD MMM YYYY') : '',
      },
      
      {
        title: 'Type',
        key: 'type',
        width: 180,
        sorter: false,
        render: (text, record) => ({
          "APPLICATION": "Application",
          "INVOICE_SINGLE": "Invoice",
          "INVOICE_SPLIT": `Split Invoice (${record.sequence.split(',')[0]} of ${record.sequence.split(',')[1]})`
        })[record.type]
      },

      {
        title: 'Relates to', 
        key: 'relates_to',
        width: 200,
        sorter: false,
        render: (text, record) => (
          <React.Fragment>

            {record.valuation && (
              <div>
                <div>
                  <NavLink to={getTeamUrl(`valuations/${record.valuation.id}/view`)}>
                    { `Valuation ${record.valuation.number} ${record.valuation.reference_suffix ? `/ ${record.valuation.reference_suffix}` : ''}` }
                  </NavLink>
                </div>
                <div>
                  {record.valuation.reference}
                </div>
              </div>
            )}

            {record.retention_payment && `Retention ${record.retention_payment.id}`}

          </React.Fragment>
        ),
      },

      {
        title: 'Quickbooks',
        dataIndex: '',
        width: 200,
        render: (text, record) => !!record.added_to_quickbooks && (
          <React.Fragment>
            <CheckCircleFilled style={{ color: '#14be71', fontSize: 15, paddingRight: 5}} />
            {'Added to Quickbooks'}
          </React.Fragment>
        )        
      },

    ]

    const invoices = reverse(sortBy(project.invoices, ['id']))

    return (

      <React.Fragment>

        <div style={{ marginBottom: 15 }}>

          <Input.Search
            style={{ width: 250 }}
            placeholder="Search"
            onChange={onChangeSearch}
            value={searchTerm}
          />

        </div>

        <Table 
          dataSource={invoices}
          rowKey={record => record.id}
          columns={columns}
          loading={fetching}
        />

      </React.Fragment>
      
    )
  }
}