import fetchServiceTypes from '../../../../../Core/Services/fetchServiceTypes'
import fetchProjectTargetCosts from '../Services/fetchProjectTargetCosts'
import fetchProjectTargetAmounts from '../Services/fetchProjectTargetAmounts'

export default () => (dispatch) => {

  dispatch(fetchServiceTypes())

  dispatch(fetchProjectTargetCosts())
  dispatch(fetchProjectTargetAmounts())

}
