import updateProjectField from '../../../../../Services/updateProjectField'
import fetchProjectTargetAmounts from '../../../Services/fetchProjectTargetAmounts'

export default (field, value) => async (dispatch) => {

  await dispatch(updateProjectField(field, value))

  dispatch(fetchProjectTargetAmounts())

}
