import { createAction, handleActions } from 'redux-actions'

const setProjectTargetAmounts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/SET_PROJECT_TARGET_AMOUNTS')
const resetProjectTargetAmounts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/RESET_PROJECT_TARGET_AMOUNTS')

const defaultState = {
  service_types: [],
  
  target_labour_total_amount: 0,
  target_labour_completed_amount: 0,
  
  target_other_total_amount: 0,
  target_other_completed_amount: 0,
  
  target_cost_total_amount: 0,
  target_cost_completed_amount: 0,
  
  target_profit_total_amount: 0,
  target_profit_completed_amount: 0,
}

const reducer = handleActions(
  {
    [setProjectTargetAmounts]: (_, { payload }) => payload,
    [resetProjectTargetAmounts]: () => defaultState,
  },
  defaultState,
)

export { setProjectTargetAmounts, resetProjectTargetAmounts, reducer }
