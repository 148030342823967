// @flow

import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Popover } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { sortBy, reverse } from 'lodash';
import { getTeamUrl } from 'Common';

type Props = {
  project: Object,
  fetching: Boolean,
  onChangeSelectedRows: Function,
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      project,
      fetching,
      onChangeSelectedRows,
    } = this.props;

    const columns = [
      {
        title: 'Reference',
        dataIndex: '',
        width: 155,
        render: (text, record) => (
          <NavLink to={getTeamUrl(`orders/${record.id}/view`)}>
            {record.reference}
          </NavLink>
        )
      },

      {
        title: 'Client / Site',
        dataIndex: '',
        width: 252,
        render: () => (

          <div>

            <div className="ellipsis">
              <strong>
                {project.client_site.client.name}
              </strong>
            </div>

            <div className="ellipsis">
              {project.client_site.name}
            </div>

          </div>

        )
      },

      {
        title: ' ',
        dataIndex: '',
        key: 'notes',
        width: 25,
        sorter: false,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },

      {
        title: 'Project No.',
        dataIndex: 'client_project_number',
        width: 200,
      },

      {
        title: 'Date',
        dataIndex: '',
        width: 120,
        render: (text, record) => (
          <div>
            {record.date ? moment(record.date).format('DD MMM YYYY') : '--'}
          </div>
        )
      },

      {
        title: 'Value',
        dataIndex: '',
        width: 120,
        render: (text, record) => (
          <span style={record.target_total !== record.total ? { backgroundColor: '#e84754', padding: 5, borderRadius: 5, color: '#FFF' } : {}}>

            {`£ ${numeral(record.target_total).format('0,0.00')}`}

          </span>
        )
      },
      
      {
        title: 'New / Extra',
        key: 'extra_works',
        width: 140,
        render: (text, record) => (
          <div>
            <LegacyIcon type={record.extra_works ? 'plus' : 'star'} style={{ color: record.extra_works ? 'limegreen' : '#1990FF' }} />
            {record.extra_works ? ' Extra Works' : ' New Works'}
          </div>
        ),
      },

      {
        title: 'Status',
        dataIndex: '',
        width: 140,
        render: (text, record) => (                  
          <React.Fragment>

            {record.order_file_storage_path ? (
              <React.Fragment>
              
                <CheckCircleFilled style={{ color: '#14be71', fontSize: 15, paddingRight: 5}} />
                {'File Uploaded'}

              </React.Fragment>

            ): (
              <React.Fragment>

                <ExclamationCircleFilled style={{ color: '#e84754', fontSize: 15, paddingRight: 5}} />
                {'File Missing'}

              </React.Fragment>
            )}


          </React.Fragment>
        )        
      }

    ];

    const orders = reverse(sortBy(project.orders, ['date']))

    return (
      <Table
        dataSource={orders}
        columns={columns}
        loading={fetching}
        rowKey='id'
        rowSelection={{
          onChange: ids => onChangeSelectedRows(ids),
        }}
      />
    )
  }
}