import { message } from 'antd'
import { updateProjectTargetCost } from '../../../../../HttpRequests/projectTargetCosts'
import fetchProjectTargetAmounts from './fetchProjectTargetAmounts'
import handleException from '../../../../../Core/Helpers/handleException'

export default (id, field, value) => async (dispatch) => {

  const data = {
    [field]: value,
  }

  try {

    await updateProjectTargetCost({ id, data })

    dispatch(fetchProjectTargetAmounts())

    message.success('Targets updated')

  } catch (error) {

    handleException(error)

  }

}
