exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2YidIDNEs7fScfH3tM1R5U {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n._33tUUYdVGD0BZ0183C_0g {\n  display: flex;\n}\n\n.hcr2i0vlTYwhGf19Wf2OO {\n  color: #999;\n}\n\n._2-vm-fOVr-0WjPSkweknQz {\n  width: 120px;\n  text-align: right;\n}\n\n._14NBRwkLb402u7wtUS5lRW {\n  width: 50px;\n  text-align: right;\n  color: #999;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/TargetCosts/Components/Expected/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,SAAS;CACV;;AAED;EACE,cAAc;CACf;;AAED;EACE,YAAY;CACb;;AAED;EACE,aAAa;EACb,kBAAkB;CACnB;;AAED;EACE,YAAY;EACZ,kBAAkB;EAClB,YAAY;CACb","file":"styles.css","sourcesContent":[".outlets {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.outlet {\n  display: flex;\n}\n\n.label {\n  color: #999;\n}\n\n.value {\n  width: 120px;\n  text-align: right;\n}\n\n.suffix {\n  width: 50px;\n  text-align: right;\n  color: #999;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"outlets": "_2YidIDNEs7fScfH3tM1R5U",
	"outlet": "_33tUUYdVGD0BZ0183C_0g",
	"label": "hcr2i0vlTYwhGf19Wf2OO",
	"value": "_2-vm-fOVr-0WjPSkweknQz",
	"suffix": "_14NBRwkLb402u7wtUS5lRW"
};