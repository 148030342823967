import { connect } from 'react-redux'
import Targets from './Targets'

import onEditField from './Handlers/onEditField'

const mapStateToProps = (state) => ({
  project: state.screens.ProjectView.project,
  projectTargetAmounts: state.screens.ProjectView.TargetCosts.projectTargetAmounts,
  fetchingProjectTargetAmounts: state.screens.ProjectView.TargetCosts.fetchingProjectTargetAmounts,
})

const actionCreators = {
  onEditField,
}

export default connect(mapStateToProps, actionCreators)(Targets)
