import { push } from 'connected-react-router';
import { clearSearchTerm } from '../State/searchTerm';
import fetchEstimates from '../Services/fetchEstimates';
import fetchOrders from '../Services/fetchOrders';
import fetchValuations from '../Services/fetchValuations';
import fetchInvoices from '../Services/fetchInvoices';
import fetchRetentionPayments from '../Services/fetchRetentionPayments';
import fetchRamsRecords from '../Services/fetchRamsRecords';

export default (activeTab) => async (dispatch, getState) => {

  const { pathname } = getState().router.location;

  dispatch(push(`${pathname}?tab=${activeTab}`));

  dispatch(clearSearchTerm());

  switch (activeTab) {
    case 'estimates':
      dispatch(fetchEstimates())
      break
    case 'orders':
      dispatch(fetchOrders())
      break
    case 'valuations':
      dispatch(fetchValuations())
      break
    case 'invoices':
      dispatch(fetchInvoices())
      break
    case 'retention_payments':
      dispatch(fetchRetentionPayments())
      break
    case 'rams_records':
      dispatch(fetchRamsRecords())
      break
    default:
      //
      break
  }

}