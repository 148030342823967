import { createAction, handleActions } from 'redux-actions'

const setFetchingProjectTargetCosts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/SET_FETCHING_PROJECT_TARGET_COSTS')
const resetFetchingProjectTargetCosts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/RESET_FETCHING_PROJECT_TARGET_COSTS')

const defaultState = false

const reducer = handleActions(
  {
    [setFetchingProjectTargetCosts]: (_, { payload }) => payload,
    [resetFetchingProjectTargetCosts]: () => defaultState,
  },
  defaultState,
)

export { setFetchingProjectTargetCosts, resetFetchingProjectTargetCosts, reducer }
