import { getRetentionPayments } from '../../../HttpRequests/retentionPayments'
import { loadRetentionPayments } from '../State/project'
import { setFetching } from '../State/fetching'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = getProps().match.params

  const params = {
    'filter[project_id]': id,
    include: 'active_invoices',
  }

  dispatch(setFetching(true))

  try {

    const response = await getRetentionPayments({ id, params })

    dispatch(loadRetentionPayments(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
