import { connect } from 'react-redux'
import Estimates from './Estimates'

import componentWillMount from './Handlers/componentWillMount'
import onChangeSelectedRows from './Handlers/onChangeSelectedRows'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentWillMount,
  onChangeSelectedRows,
}

export default connect(mapStateToProps, actionCreators)(Estimates)
