import { createAction, handleActions } from 'redux-actions'

const setFetchingProjectTargetAmounts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/SET_FETCHING_PROJECT_TARGET_AMOUNTS')
const resetFetchingProjectTargetAmounts = createAction('SCREENS/PROJECT_VIEW/TARGET_COSTS/RESET_FETCHING_PROJECT_TARGET_AMOUNTS')

const defaultState = false

const reducer = handleActions(
  {
    [setFetchingProjectTargetAmounts]: (_, { payload }) => payload,
    [resetFetchingProjectTargetAmounts]: () => defaultState,
  },
  defaultState,
)

export { setFetchingProjectTargetAmounts, resetFetchingProjectTargetAmounts, reducer }
